import React, { useState } from 'react';
import Hamburger from 'hamburger-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { MdOutlineRestaurantMenu } from 'react-icons/md';

import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  
  const handleMenuClick = () => {
    setOpen(false);
    navigate('/vårmeny');
  };
  return (<>
  <nav className='app__navbar'>
   <div className="app__navbar-logo">
      <a href="/">  <img  src={images.perla} alt="app__logo" /></a>
    </div>
    <ul className="app__navbar-links">
        <li className="p__opensans"><a href="/">HEM</a></li>
        
        <li className="p__opensans"><a href="meny" onClick={handleMenuClick}>MENY</a></li>
        <li className="p__opensans"><a href="pizzameny">PIZZA MENY</a></li>
      <li className="p__opensans"><a href="aktiviteter">AKTIVITETER</a></li>
      <li className="p__opensans"><a href="event">EVENT</a></li>
        {/*<li className="p__opensans"><a href="erbjudande">ERBJUDANDE</a></li>*/}
      </ul>
      <div className="app__navbar-contact">
        <a href="Kontakt" className="p__opensans">Kontakta oss</a>
        <div />
        
        <a href="bokabord" className="p__opensans">BOKA BORD</a>
    </div>
      <div className="app__navbar-smallscreen">
        <Hamburger toggled={isOpen} toggle={setOpen} color="#fff" fontSize={27} />
        {isOpen && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setOpen(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><NavLink to='/' onClick={() => setOpen(false)}>HEM</NavLink></li>
              <li><a href="meny" onClick={() => setOpen(false)}>MENY</a></li>
              <li><a href="pizzameny" onClick={() => setOpen(false)}>PIZZA MENY</a></li>
              <li><a href="aktiviteter" onClick={() => setOpen(false)}>AKTIVITETER</a></li>
              <li className="opensans"><a href="event">EVENT</a></li>
              {/*<li><a href="erbjudande" onClick={() => setOpen(false)}>ERBJUDANDE</a></li>*/}
            </ul>
          </div>
        )}
      </div>
     
  </nav>
 <div className='line'></div>
    </>
);
};

export default Navbar;
