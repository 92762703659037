import React from 'react';
import './reservation.css';

const Reservations = () => {
  
  
  return (
    <div className='containerRes'>
    <div  className="reservation-form">
      <h2>BOKA BORD </h2>
      <p>
       
          Det är enkelt att boka bord hos oss!
          <br/>

          Ring oss direkt på 031-795 01 07 eller mejla till
          
          mail2perla@yahoo.se
        
       
        </p>
     

      <div className='bokaMap'>
      <iframe scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=583&amp;height=321&amp;hl=en&amp;q=perla%20%20k%C3%A5llered+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="583" height="321" frameborder="0"></iframe>

      
      </div>
          </div>
    </div>
  )
}

export default Reservations;
