// BiljardPage.js
import React from 'react';
import './spel.css';
import image from '../../constants/images';

const BiljardPage = () => {
  return (<>
     <header className="spelImage">
        <img src={image.spelBg} alt="Header" className="headerImage" />
        <h1 className='spelText'>Shuffleboard& Biljard </h1>
      </header>
    <div className="biljard-page">
      <div className="left-section">
        <img src={image.biljard} alt="Biljard Table 1" />
      </div>
     
      <div className="text-section">
        <h2>Biljard</h2>
        <p>
        Spela biljard som ett proffs på Perla!
        </p>
       
        <p>
        Tisdag - Söndag
          <br />
          20kr per spel   
        </p>
       
      </div>
     
    </div>
    <div className="biljardPage">

      
      <div className="right-section">
        <img src={image.menu} alt="Biljard Table 2" />
      </div>
      <div className="text-section">
              <h2>Shuffleboard</h2>
              <p>
              Spelet för dig som vill vinna!
              </p>
             
              <p>
              Tisdag - Söndag
                <br />
                200kr per timme 
              </p>
              
              <p>OBS! Minsta debitering 1 timme.</p>
            </div>
    </div>
    </>
  );
};

export default BiljardPage;
