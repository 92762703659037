export const categories = ["Förrätter", "Våra meny", "Grill" ];

export const subcategories = {

  Förrätter: [
  
    {
 
      items: [
        { name: "1-Lökringar",  description: "Friterade lökringar med aioli.", price: 72 },
        { name: "2- Torshi", description: "Inlagda grönsaker.", price: 64 },
        { name: "3- Tzatziki", description: "Strimmad gurka, yoghurt, persilja.", price: 64 },
        { name: "4- Zeyton",  description: " Marinerade kalamata oliver.", price: 64 },
       
        { name: "5- Sallad Shirazi", description: "Hackade lök, gurka och tomat blandas med citronsaft.", price: 64 },
        { name: "6- Mast Mosusir",  description: " Yoghurt blandat med torkat vild vitlök.", price: 64 },
      ],
    },
   

  ],


  "Våra meny": [
  
  
    {
      title: "À la carte",
      items: [
        { description: "Biffstek med aromsmör, grillad tomat och friterad klyftpotatis/ alt. Pommes frites", price: 199 },
        { description: "Entrecote med aromsmör, grillad tomat och friterad klyftpotatis/ alt. Pommes frites", price: 220 },
      
      ],
    },
    
    {
      title: "Pastarätter ",
      items: [
        { name: "Biffpasta", description: "Tagliatelle, lök, strimlad biff, rödvin, kalvfond och grädde. ", price: 179 },
        { name: "Skinkpasta", description: "Tagliatelle, purjolök, tärnad tomat, vitt vin, olivolja, stureost, lufttorkad skinka samt ruccolasallad. ", price: 179 },
        { name: "Kycklingpasta", description: "Tagliatelle, marinerade kycklingbitar, purjolök, tärnad tomat, piri piri (kan väljas bort), vitt vin, grädde samt Rose’s lime. ", price: 179 },
        { name: "Skaldjurspasta", description: "Tagliatelle, purjolök, vitlök, färska räkor, vitt vin samt grädde ", price: 179 },
      ],
    },
    {
      title: "Hamburgare ",
      items: [
        { name: "Cheese Burger", description: "BBQ-sås, cheddarost, dressing, pommes frites. ", price: 145},
        { name: "Bacon Burger", description: "BBQ-sås, bacon, dressing, pommes frites", price: 145 },
        { name: "Luxury Burger", description: "Cheddarost, parmaskinka, dressing, pommes frites", price: 150 },
       
      ],
    },
    {
      title: "Sallader",
      items: [
        { name: "Italiensk sallad ", description: "Sallad, soltorkade tomater, mozzarellaost, parmaskinka, ruccola, pesto, rödlök . ", price: 140 },
        { name: "Grekisk sallad", description: "Sallad, oliver, paprika, rödlök, tomat, gurka, salladost, vitlökssås. ", price: 140 },
        { name: "Caesarsallad ", description: "Sallad, tomater, grillad kycklingfile, brödkrutonger, caesardressing ", price: 169 },
        { name: "Räksallad", description: "Handskalade inlagda räkor, sallad, tomat, gurka, citron, Rhode Islandsås.  ", price: 169 },
      ],
    },

  ],
  
 
  Grill: [
    {
      title: "Grillade Rullar",
      items: [
        { name:"1. Kobide", description: "Lammfärsspett, sallad, lök, tomat, finhackad persilja, tunnbröd & peperoni.", price: 169 },
        { name: "2. Joje", description: "Saffran, limemarinerade kycklingfilé, sallad, lök, tomat, finhackad persilja, tunnbröd & peperoni. ", price: 179 },
        { name: "3. Chenjeh", description: "Marinerad lammytterfilé, sallad, lök, tomat, finhackad persilja, tunnbröd & peperoni.", price: 189 },
      ],
    },
    {
      title: "Persisk Grill",
      items: [
        { name: "4. Kobide", description: "Lammfärsspett, grillad tomat & paprika, tzatziki med ris och räfflad potatis.", price: 189 },
        { name: "5. Joje kebab", description: "Saffran, limemarinerade kycklingfiléspett, grillad tomat & paprika, tzatziki med ris och räfflad potatis.", price: 189 },
        { name: "6. Joje Makhsos", description: "Två spett med lammfärs och saffran, limemarinerad kycklingfilé, grillad tomat & paprika, tzatziki med persiskt ris räfflad potatis.", price: 249 },
        { name: "7. Bakhtiyari", description: "Ett spett med saffrans, limemarierad kycklingfilé, marinerad lammytterfilé, grillad tomat & paprika, tzatziki med persisk ris och räfflad potatis. (tillval rödvinssås)", price: 259 },
        { name: "8. Chenjeh | Bareh", description: "Ett spett marinerad lammytterfilé, grillad tomat & paprika, tzatziki med persiskt ris och räfflad potatis.(tillval rödvinssås)", price: 229 },
        { name: "9. Shishlik", description: "Vitlöksmarinerade lammracks, grillad tomat & paprika, tzatziki med persiskt ris räfflad potatis.", price: 279 },
        { name: "10. Sultani", description: "Två spett med marinerad lammytterfilé, lammfärsspett. grillad tomat & tomatsås, tzatziki med persiskt ris räfflad potatis.", price: 309 },
      ],
    },
  ],



  
};
