import React from 'react';
import './Event.css'; 
import image from '../../constants/images';

const offersData = [
  {
    id: 1,
    image: 'music.png',
    title: '',
    description: '',
  },
  // Add more music events as needed
];

const Event = () => {
  return (
    <div className='headerEr'>
      <div className="offers-container">
        <header className="erbjudande-header">
          <img src={image.event} alt="Header" className="header-image" loading="lazy" />
          <h1>EVENT</h1>
        </header>
        {offersData.map((offer) => (
          <div key={offer.id} className="offer-card">
            <img src={image.eventMusic} alt={offer.title} />
            <h2>{offer.title}</h2>
            <p>{offer.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Event;
