import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './SpecialMenu.css';

const Spel = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Våra aktiviteter" />
      <h1 className="headtext__cormorant">Shuffleboard& Biljard </h1>
    </div>

    <div className="app__specialMenu-menu">
 

      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

    </div>

    <div style={{ marginTop: 15 }}>
      <button type="button" className="custom__button"><a href='aktiviteter'>LÄS MER</a></button>
    </div>
  </div>
);

export default Spel;