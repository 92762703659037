

import React from "react";
import "./GrillMeny.css";
import image from '../../constants/images';

const GrillMenu = () => {
 
  const grillMenuItems = [
    {
      title: "GRILLADE RULLAR",
      items: [
        { name:"1. Kobide", description: "Lammfärsspett, sallad, lök, tomat, finhackad persilja, tunnbröd & peperoni.", price: 169 },
        { name: "2. Joje", description: "Saffran, limemarinerade kycklingfilé, sallad, lök, tomat, finhackad persilja, tunnbröd & peperoni. ", price: 179 },
        { name: "3. Chenjeh", description: "Marinerad lammytterfilé, sallad, lök, tomat, finhackad persilja, tunnbröd & peperoni.", price: 189 },
      ],
    },
    {
      title: "PERSISK GRILL",
      items: [
        { name: "4. Kobide", description: "lammfärsspett, grillad tomat & tomatsås, tzatziki med ris och räfflad potatis.", price: 189 },
        { name: "5. Joje kebab", description: "Saffran, limemarinerade kycklingfiléspett, grillad tomat & tomatsås, tzatziki med ris och räfflad potatis.", price: 189 },
        { name: "6. Joje Makhsos", description: "Två spett med lammfärsspett och saffran, limemarinerad kycklingfilé, grillad tomat & tomatsås, tzatziki med persiskt ris räfflad potatis.", price: 249 },
        { name: "7. Bakhtiyari", description: "Två spett med saffrans, limemarierad kycklingfilé, marinerad lammytterfilé, grillad tomat & tomatsås, tzatziki med persisk ris och räfflad potatis. (tillval rödvinssås)", price: 309 },
        { name: "8. Chenjeh | Bareh", description: "Ett spett marinerad lammytterfilé, grillad tomat, tzatziki med persiskt ris och räfflad potatis.", price: 229 },
        { name: "9. Shishlik", description: "Vitlöksmarinerade lammracks, grillad tomat & tomatsås, tzatziki med persiskt ris räfflad potatis.", price: 269 },
        { name: "10. Sultani", description: "Två spett med marinerad lammytterfilé, lammfärsspett. grillad tomat & tomatsås, tzatziki med persiskt ris räfflad potatis.", price: 309 },
      ],
    },
  ];

  return (
    <div className="grill-menu-container">
      <div className="top-image">
        <img src={image.grillMenu} alt="Grill Meny" className="top-image" />
        <h1 className="menu-title-card">GRILL MENY</h1>
      </div>
      <div className="grill-menu-carts">
        {grillMenuItems.map((cart, cartIndex) => (
            <div key={cartIndex} className="grill-menu-cart">
                 <div className="menu-cardGrill">
            <h2>{cart.title}</h2>
            <div className="grill-menu-items">
              {cart.items.map((item, itemIndex) => (
                <div key={itemIndex} className="grill-menu-item">
                  <h2>{item.name}</h2>
                  <p className="description">{item.description}</p>
                  <p className="price">{item.price}:-</p>
                </div>
              ))}
            </div>
                </div>
                </div>
        ))}
                
      </div>
    </div>
  );
};

export default GrillMenu;

