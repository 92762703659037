import React, { useState } from "react";
import "./PizzaMeny.css";
import pizzaMenuItems from "./pizza";
import image from "../../constants/images";

const PizzaMenu = () => {
  const [selectedCategory, setSelectedCategory] = useState("DIVERSE PIZZOR"); // Set "DIVERSE PIZZOR" as the initial selected category

  // Filter the pizza items based on the selected category
  const filteredPizzaItems = selectedCategory
    ? pizzaMenuItems.filter((pizza) => pizza.tags.includes(selectedCategory))
    : pizzaMenuItems;

  return (
    <div className="pizza-menu-container">
      <div className="menu-card-image">
        <img src={image.pizza} alt="meny" />
        <h3 className="menu-title-card">PIZZOR MENY</h3>
      </div>

      <div className="filter-buttons">
        <button
          className={selectedCategory === "DIVERSE PIZZOR" ? "active" : ""}
          onClick={() => setSelectedCategory("DIVERSE PIZZOR")}
        >
          Diverse Pizzor
        </button>
        <button
          className={selectedCategory === "KÖTTFÄRSPIZZOR" ? "active" : ""}
          onClick={() => setSelectedCategory("KÖTTFÄRSPIZZOR")}
        >
          KÖTTFÄRSPIZZOR
              </button>
              
              <button
          className={selectedCategory === "OXFILÉPIZZOR" ? "active" : ""}
          onClick={() => setSelectedCategory("OXFILÉPIZZOR")}
        >
          OXFILÉPIZZOR      
              </button>
              <button
          className={selectedCategory === "INBAKADEPIZZOR" ? "active" : ""}
          onClick={() => setSelectedCategory("INBAKADEPIZZOR")}
        >
          INBAKADE PIZZOR 
              </button>
              
              <button
          className={selectedCategory === "KEBABPIZZOR" ? "active" : ""}
          onClick={() => setSelectedCategory("KEBABPIZZOR")}
        >
          KEBABPIZZOR
              </button>
              
              <button
          className={selectedCategory === "LYXPIZZOR" ? "active" : ""}
          onClick={() => setSelectedCategory("LYXPIZZOR")}
        >
          LYXPIZZOR 
              </button>
              
        <button
          className={selectedCategory === "TILLBEHÖR" ? "active" : ""}
          onClick={() => setSelectedCategory("TILLBEHÖR")}
        >
         TILLBEHÖR
        </button>
      </div>

      {selectedCategory !== "TILLBEHÖR" && (
        <h5 className="menu-title"> Alla pizzor med tomatsås och ost</h5>
      )}

      {selectedCategory && <h2 className="category-title">{selectedCategory}</h2>}

      <div className="pizza-items">
        {filteredPizzaItems.map((pizza) => (
          <div key={pizza.id} className="pizza-item">
            <h2>{pizza.name}</h2>
            <p>{pizza.description}</p>
            <p className="price">{pizza.price}:-</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PizzaMenu;

