import React from 'react';
import { Link } from 'react-router-dom';
import product1 from '../../assets/grillMenu-bg.jpg';
import product2 from '../../assets/menu-bg.jpg';
import product3 from '../../assets/lunchMenu-bg.jpg';
import './specialities.css';


const ProductsPreview =  ({ handleCategoryChange }) => {

  const data = [
    {
      img: product1,
      alt: 'Grill Meny',
      name: 'GRILL MENY',
      desc: 'Här hittar du vår grill meny',
      button: {
        label: 'SE MENY',
        link: '/GrillMeny' 
      }
    },
    {
      img: product2,
      alt: 'Meny',
      name: 'MENY',
      desc: 'Här hittar du vår meny',
      button: {
        label: 'SE MENY',
        link: '/meny' 
      }
    },
    {
      img: product3,
      alt: 'lunch',
      name: 'LUNCH MENY',
      desc: 'Här hittar du vår lunch meny',
      button: {
        label: 'SE MENY',
        link: '/lunchMeny' 
      }
      
    
    },
  ];

 
  return (
    <div className='menu-container1'>
    
    <div className='menu-container1'>
        {data.map((product, index) => {
          return (
            <div className="product-card" key={index}>
              <img src={product.img} alt={product.alt} />
              <div className="product-name">{product.name}</div>
              <div className="product-desc">{product.desc}</div>
              <Link to={product.button.link} className="product-button">
            {product.button.label}
              </Link>
              </div>
          )
          
        })}
      </div>

      </div>
  )
}

export default ProductsPreview;
