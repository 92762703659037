import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

import images from '../../constants/images';

const Footer = () => {
  return (<>
    <div className='line'/>
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
        <img  src={images.perla} alt="app__logo" />
        </div>
       
        <div className="footer-contact">
        
        <div className="footer-phone">Tel: 031-795 01 07 </div>
          <div className="footer-email">E-post: mail2perla@yahoo.se
          </div>
          <div className="footer-adress">ADRESS: Gamla riksvägen 54, 428 30 Kållered</div>
          
        </div>
        <div className="footer-social">
        <a href="https://www.facebook.com/restaurangperla.se/"><FontAwesomeIcon icon={faFacebook} /></a>
          
        </div>
      </div>
      <div>
      ©2024 Perla Restaurang 
     </div>
    </footer>
    </>
  );
};

export default Footer;

