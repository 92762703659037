import React from 'react';
import Header from '../../components/Header/Header';
import Spel from '../../components/Spel/SpecialMenu';
import  ProductsPreview  from '../../components/Specialities/ProductsPreview';



const Hem = () => {
  return (
      <div>
      <Header />
      <ProductsPreview  />


      <Spel />
    
    </div>
  )
}

export default Hem;