
const pizzaMenuItems = [
  { id: 1, name: "1.Montana", description: "Lök, champinjoner, salami, vitlök", price: 125, tags: ["DIVERSE PIZZOR"] },
  { id: 2, name: "2.Margerita", description: "", price: 120, tags: ["DIVERSE PIZZOR"] },
  { id: 3, name: "3.Cacciatora", description: "Salami", price: 120, tags: ["DIVERSE PIZZOR"] },
  { id: 4, name: "4.Vesuvio", description: "Skinka", price: 120, tags: ["DIVERSE PIZZOR"] },
  { id: 5, name: "5.Capricciosa ", description: "Skinka, champinjoner", price: 120, tags: ["DIVERSE PIZZOR"] },
  { id: 6, name: "6.Hawaii", description: "Skinka, ananas", price: 125, tags: ["DIVERSE PIZZOR"] },
  { id: 7, name: "7.Venezia", description: "Skinka, tonfisk", price: 125, tags: ["DIVERSE PIZZOR"] },
  { id: 8, name: "8.Pescatore", description: "Tonfisk, lök, paprika", price: 120, tags: ["DIVERSE PIZZOR"] },
  { id: 9, name: "9.Quattro stagioni", description: "Skinka, champinjoner, räkor, kronärtskocka", price: 140, tags: ["DIVERSE PIZZOR"] },
  { id: 10, name: "10.Al Forno", description: "Bacon, lök, ägg, svartpeppar", price: 125, tags: ["DIVERSE PIZZOR"] },
  { id: 11, name: "11.Vegetariana", description: "Paprika, lök, champinjoner, oliver, sparris,Kronärtskocka ", price: 130, tags: ["DIVERSE PIZZOR"] },
  { id: 12, name: "12.Tomasso", description: "Skinka, räkor", price: 130, tags: ["DIVERSE PIZZOR"] },
  { id: 13, name: "13.Marinara", description: "Räkor, musslor, crabfish", price: 140, tags: ["DIVERSE PIZZOR"] },


  { id: 14, name: "14.Capri", description: "Skinka, köttfärs, lök, vitlök", price: 130, tags: ["KÖTTFÄRSPIZZOR"] },
  { id: 15, name: "15.Balkan", description: "Köttfärs, bacon, salami, lök, champinjoner", price: 130, tags: ["KÖTTFÄRSPIZZOR"] },
  { id: 16, name: "16.Pizza speciale", description: " Tomat, ost, skinka, lök, peperoni, köttfärs, vitlök, champinjoner", price: 150, tags: ["KÖTTFÄRSPIZZOR"] },
  { id: 17, name: "17.Mexicana", description: "Köttfärs, paprika, lök, vitlök, piripiri, peperoni", price: 120, tags: ["KÖTTFÄRSPIZZOR"] },
  { id: 18, name: "18.Cannibale", description: "köttfärs, svartpeppar", price: 125, tags: ["KÖTTFÄRSPIZZOR"] },
  { id: 19, name: " 19.Carusel", description: " Salami, paprika, färska tomater, köttfärs", price: 140, tags: ["KÖTTFÄRSPIZZOR"] },

  { id: 20, name: "20.Barcelona", description: "Oxfilé, champinjoner, paprika, vitlök, peperoni, bearnaisesås ", price: 140, tags: ["OXFILÉPIZZOR"] },
  { id: 21, name: "21.Biff Oscar", description: "Tomat, ost, oxfilé, sparris, bearnaisesås", price: 140, tags: ["OXFILÉPIZZOR"] },
  { id: 22, name: "22.Chano", description: "Tomat, ost, vitlök, oxfilé, gorgonzola, bearnaisesås", price: 140, tags: ["OXFILÉPIZZOR"] },
  { id: 23, name: "23.Africana", description: "Tomat, ost, oxfilé, banan, ananas, jordnötter, curry", price: 140, tags: ["OXFILÉPIZZOR"] },

  { id: 24, name: "24.Perla Speciale", description: "Oxfilé, champinjoner, lök, vitlök, gorgonzola, piri piri, bearnaisesås", price: 150, tags: ["OXFILÉPIZZOR"] },
  { id: 25, name: "25.Gorgonzola", description: "Oxfilé, gorgonzola", price: 140, tags: ["OXFILÉPIZZOR"] },
  { id: 26, name: "26.Real", description: "Oxfilé, champinjoner, ägg, bearnaisesås", price: 140, tags: ["OXFILÉPIZZOR"] },

  { id: 27, name: "27.Calzone", description: "Skinka", price: 120, tags: ["INBAKADEPIZZOR"] },
  { id: 28, name: "28.Ciao ciao special", description: "Oxfilé, lök, bearnaisesås", price: 140, tags: ["INBAKADEPIZZOR"] },
  
  
  { id: 29, name: "29.Kebabpizza", description: " Grillat lammfärsspett, lök,peperoni, valfri sås", price: 159, tags: ["KEBABPIZZOR"] },
  { id: 30, name: "30.Kebab special", description: "Grillat lammfärsspett, lök, färska tomater, isbergssallad, peperoni, valfri sås", price: 159, tags: ["KEBABPIZZOR"] },
  { id: 31, name: "31.Rhodos", description: "Grillat lammfärsspett, lök, tomater, salladost, peperoni, vitlökssås", price: 159, tags: ["KEBABPIZZOR"] },
  { id: 32, name: "32.Småland special", description: "Grillat lammfärsspett, pommes, valfri sås", price:  159, tags: ["KEBABPIZZOR"] },


  { id: 33, name: "33.Le chef", description: "Mozzarella, parmaskinka, soltorkade, tomater, oliver", price: 145, tags: ["LYXPIZZOR"] },
  { id: 34, name: "34.Di parma", description: "Mozzarella, parmaskinka, färska tomater, riven parmesan, ruccola", price: 145, tags:["LYXPIZZOR"] },
  { id: 35, name: "35.Siciliana", description: "Mozzarella, parmaskinka, soltorkade, tomater, tomater, salladost, pesto", price: 145, tags: ["LYXPIZZOR"] },
  { id: 36, name: "36.Quattro Formaggio", description: "Mozzarella, salladost, gorgonzola, valnötter", price: 145, tags: ["LYXPIZZOR"] },
  { id: 37, name: "37.Ruccola", description: "Mozzarella, ruccola, oliver, salladost, soltorkade tomater", price: 145, tags: ["LYXPIZZOR"] },
  


  { id: 38, name: "Kött", price: 30, tags: ["TILLBEHÖR"] },
  { id: 39, name: "Skaldjur och ost", price: 30, tags: ["TILLBEHÖR"] },
  { id: 40, name: "Sås och övrigt", price: 15, tags: ["TILLBEHÖR"] },
  { id: 41, name: "Delat Pizza",  price: 20, tags: ["TILLBEHÖR"] },
    
  ];
  
  export default pizzaMenuItems;
  