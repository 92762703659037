import React from 'react';
import { SubHeading } from '..';
import { images } from '../../constants';
//import SpecialMenu from '../../container/Menu/SpecialMenu';

import './Header.css';

const Header = () => (<>
  <div className="app__header app__wrapper section__padding" id="home">
  <div className="app__wrapper_info">
    <SubHeading title="Perla Grillhouse" />
    <h1 className="app__header-h1">Välkommen till perla Grillhouse</h1>
    <p className="p__opensans" style={{ margin: '1rem 0' }}>Hos oss hittar du smakrika, hälsosamma rätter som serveras snabbt, fräscht och enkelt i en varm och lekfull miljö. </p>
    <button type="button" className="custom__button"> <a href="meny"> MENY </a></button>
  </div>

  <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" style={{ margin: '1rem 0', width: '100%'}} />
  </div>
    </div>
   
    </>
);


export default Header;
