import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar, Footer } from './components';
import './App.css';
import Hem from './Pages/Hem/Hem';
import Spel from './Pages/Spel/Spel';
import Boka from './Pages/BokaBord/Reservations';
import Meny from './Pages/Menuitem/MenuItem';
import Kontakt from './Pages/Kontakt/Kontakt';
import GrillMeny from './Pages/Menuitem/GrillMeny';
//import LunchMeny from './Pages/Menuitem/LunchMeny';
import Offer from './Pages/Offer/Offer';
import Pizzameny from './Pages/Menuitem/PizzaMeny';
import Event from './Pages/Event/Event';

const App = () => (
  <div>
  
  <BrowserRouter >
    <Navbar />
    <Routes>
        <Route path="/" element={<Hem />} />
        <Route path="aktiviteter" element={<Spel />} />
        <Route path="bokabord" element={<Boka />} />
        <Route path="meny" element={<Meny />} />
        <Route path="grillMeny" element={<GrillMeny />} />
        {/*<Route path="lunchMeny" element={<LunchMeny />} />*/}
        <Route path="pizzaMeny" element={<Pizzameny />} />
        <Route path="erbjudande" element={<Offer />} />
        <Route path="event" element={<Event />}  />
        <Route path="kontakt" element={<Kontakt />}  />
        
    
      </Routes>
      <Footer/>
      </BrowserRouter>
  </div>
);

export default App;
