import React from 'react';
import './Offer.css'; 
import image from '../../constants/images';


const offersData = [
  {
    id: 1,
    image: 'offer1.jpg',
    title: 'Specialerbjudande 1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    id: 2,
    image: 'offer2.jpg',
    title: 'Specialerbjudande 2',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  
];

const Offer = () => {
    return (
      <div className='headerEr'>
      <div className="offers-container">
          <header className="erbjudande-header">
        <img src={image.erbjudande} alt="Header" className="header-image" />
        <h1>ERBJUDANDE</h1>
      </header>
      {offersData.map((offer) => (
        <div key={offer.id} className="offer-card">
          <img src={offer.image} alt={offer.title} />
          <h2>{offer.title}</h2>
          <p>{offer.description}</p>
        </div>
      ))}
            </div>
            </div>
  );
};

export default Offer;
