import React, { useState } from "react";
import "./MenuItem.css";
import { categories, subcategories } from "./data";
import image from '../../constants/images';

const Menu = () => {
  const [activeCategory, setActiveCategory] = useState("Våra meny");

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  return (
    <div className="menu-container">
      <div className="menu-card-image">
        <img src={image.grillMenu} alt="meny" />
        <h3 className="menu-title-card">MENY</h3>
      </div>
      <div className="categories">
        {categories.map((category) => (
          <button
            key={category}
            className={`category-btn ${
              activeCategory === category ? "active" : ""
            }`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="subcategories">
        {subcategories[activeCategory].map((subcategory, index) => (
          <div
            key={subcategory.title}
            className={`subcategory ${
              activeCategory === "Grillade Rullar" ? "grillade-rullar" : ""
            }`}
          >
           
            <div className="menu-card">
              {activeCategory === "Lunch" && index === 0 && (
                <h3 className="menu-card-title">Vecka2</h3>
              )}
              {subcategory.title === "Pastarätter" && (
                <strong>{subcategory.title}</strong>
              )}
              {subcategory.title !== "Pastarätter" && subcategory.title}
              {subcategory.items.map((item) => (
                <div key={item.name} className="menu-item">
                  <p className="menu-item-title">{item.name}</p>
                  <p className="menu-item-description">{item.description}</p>
                  <p className="menu-item-price">{item.price}:-</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
