import React from 'react';
import './Kontakt.css';
import images from '../../constants/images';

const Kontakt = () => {
  const telephoneNumber = '031-795 01 07';
  const email = 'mail2perla@yahoo.se ';
  const address = 'Gamla riksvägen 54, 428 30 Kållered';
  const openingHours = ' 12.00 - 22.00';
  const openingHours1 = ' 12.00 - 23.00';
  const openingHours2 = ' 13.00 - 23.00';

  return (
    <div className="contact-us-container">
      <div className="contact-info">
        <h2>KONTAKTA OSS</h2>
        <p>
        Vill du komma i kontakt med oss är du varmt välkommen att ringa oss eller maila.
        </p>
        <div className="contact-details">
          <div className="contact-item">
            <div className="contact-item-label">Telefon:</div>
            <div className="contact-item-value">{telephoneNumber}</div>
          </div>
          <div className="contact-item">
            <div className="contact-item-label">E-post:</div>
            <div className="contact-item-value">{email}</div>
          </div>
          <div className="contact-item">
            <div className="contact-item-label"></div>
            <div className="contact-item-value">{address}</div>
          </div>
          <div className="opening-hours">
            <h3>Öppettider:</h3>
            <div className="opening-hours-item">
              <div className="opening-hours-day">Tisdag - Torsdag:</div>
              <div className="opening-hours-time">{openingHours}</div>
            </div>
            <div className="opening-hours-item">
              <div className="opening-hours-day">Fredag - Söndag:</div>
              <div className="opening-hours-time">{openingHours1}</div>
            </div>
            <div className="opening-hours-item">
              <div className="opening-hours-day">Lördag - Söndag:</div>
              <div className="opening-hours-time">{openingHours2}</div>
            </div>
          </div>
        </div>
          </div>
          <div className="map-image-container">
        <img src={images.kontakt} alt="kontakt" className="map-image" />
      </div>
    </div>
  );
};

export default Kontakt;
